@font-face {
  font-family: "money";
  src: url("./fonts/Montserrat.ttf");
  font-weight: 100 900;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.selectCustom {
  width: 100%;
  padding: 8.5px 15px;
  border-radius: 16px;
  border: 2px solid #d9d9d9;
}

.selectCustom:hover {
  border-color: black;
}

.nextui-input-block-label {
  text-align: start !important;
}
